<template>
  <v-app>
    <v-app-bar app :elevation="0" density="compact">
      <!-- <v-app-bar-nav-icon @click="toggleDrawer"/> -->
      <v-app-bar-title>
        <v-btn @click="refresh()" variant="text" class="px-1">
          <v-img src="./assets/logo.svg" width="25px" height="25px" style="margin:5px" />
          <span style="margin: auto; margin-left: 10px;">AI Tutor</span>
        </v-btn>
      </v-app-bar-title>
      <template v-slot:append>
        <!-- <v-btn v-if="theme.global.name.value=='light'" @click="toggleTheme()" icon="mdi-lightbulb"></v-btn>
        <v-btn v-if="theme.global.name.value=='dark'" @click="toggleTheme()" icon="mdi-lightbulb-on-outline"></v-btn> -->
      <!-- <v-btn icon>
        <v-icon icon="mdi-account-circle"></v-icon>
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(item, index) in accountMenuItems"
              :key="index"
              :value="index"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn> -->
      </template>
    </v-app-bar>
    <!-- <v-navigation-drawer app v-model="drawerOpen">
      <v-list dense>
        <v-list-item
            v-for="item in sideMenuItems"
            :key="item.title"
            link
            @click="routeTo(item.path)"
          >
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
    <v-main>
      <main-component></main-component>
      <v-footer class="justify-center" color="#00111a">

        <v-container class="w-auto">
          <v-row>
            <h3 class="my-2 text-center">Brought to you in collaboration with Activeloop, Towards AI, & Intel Disruptor Initiative
            </h3>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-img height="40px" width="100px" src="./assets/activeloop.png"></v-img>
            <v-img height="40px" width="100px" src="./assets/towardsailogo.png"></v-img>
            <v-img height="40px" width="100px" src="./assets/intel.png"></v-img>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useTheme } from 'vuetify'
import MainComponent from '@/components/MainComponent.vue';

export default defineComponent({
  name: 'App',

  components: {
    MainComponent
  },
  data () {
    return {
      accountMenuItems: [
        {title : "setting", icon : "mdi-cog-outline"},
        {title : "logout", icon : "mdi-logout"}
      ],
      sideMenuItems: [
        { title: 'Experiment', icon: 'mdi-microscope', path: '/experiment' },
      ],
    }
  },
  methods: {
    toggleTheme () {
      this.theme.global.name.value = this.theme.global.current.value.dark ? 'light' : 'dark'
    },
    refresh() {
      location.reload() 
    }
  },

  setup() {
    const drawerOpen = ref(false);
    const theme = useTheme()
    console.log("theme",theme.global.name.value)
    const toggleDrawer = () => {
      drawerOpen.value = !drawerOpen.value;
    }

    return {
      drawerOpen,
      toggleDrawer,
      theme
    }
  },
})
</script>
<style>

/* .first-section {
  background: linear-gradient(217deg, rgba(255, 100, 255, 0.492), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(101, 228, 253, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(91, 91, 252, 0.8), rgba(0,0,255,0) 70.71%);
} */

#app {
  /* background:url('./assets/background1.svg') no-repeat center center fixed !important;
  background-size: cover; */
}

html,body {
  /* overflow: hidden; Hide scrollbars */
}

/* width */
::-webkit-scrollbar {
  display: none;
}

</style>
