<template>
  <!-- <v-container > -->
    <v-spacer style="height: 10vh; background-color: #002233; "/>
    <v-row style="background-color: #002233;color : white;">
      <v-container>
        <v-row class="justify-center vertical-align-center pb-3" >
          <h1 style="font-size:6rem; text-align: center;" >Towards AI's <span class="brand-title" style="no-">AI Tutor</span></h1>
        </v-row>
        <v-row class="justify-center vertical-align-center" >
          <h2 class="text-center mx-5">
          Hi! I am Towards AI's AI tutor, built to answer anything about LLMs and powered by Deep Lake. I’ll be your course companion for the <a href="https://learn.activeloop.ai/collections">GenAI 360</a> certification courses.
        </h2>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="mb-8">
      <chat-bot/>
    </v-row>
    <v-spacer style="height: 5vh;"/>
    <v-row class="my-8">
      <instructions/>
    </v-row>
    <v-img src="../assets/background2.svg" width="100%" />
    <v-spacer style="height: 5vh;background-color: #002233"/>

    <v-row style="background-color: #002233;color: white;" class="py-10 d-flex justify-center">
      <course-section/>
    </v-row>
    <v-spacer style="height: 5vh;background-color: #002233"/>
  <!-- </v-container> -->
</template>
  
<script lang='ts'>
  import { defineComponent, ref } from 'vue'
  import { useTheme } from 'vuetify'

  import ChatBot from '@/components/ChatBot.vue';
  import Instructions from '@/components/InfoBot.vue';
  import CourseSection from '@/components/CourseSection.vue';

  export default defineComponent({
      name: 'HelloWorld',

      components: {
          ChatBot,
          Instructions,
          CourseSection,
      },
      data () {
        return {
          showMenu : false,
          fullscreeen: false,
          hover : [false,false,false],
          sections : [
            { hover :false, hook:'hook1', text: "How it was made"},
            { hover :false, hook:'hook2', text: "Course"},
            { hover :false, hook:'hook3', text: "Contact us"}
          ],
          sideMenuItems: [
              { title: 'Experiment', icon: 'mdi-microscope', components: 'chatbotinfo' },
              { title: 'Experiment', icon: 'mdi-microscope', components: 'chatbotinfo' },
              { title: 'Experiment', icon: 'mdi-microscope', components: 'chatbotinfo' },
          ],
        }
      },
      methods: {
        showComponent(componentName : string) {
          console.log(componentName)
        },
        toggleDrawer() {
          console.log("toggleDrawer")
        },
        hookTo(hook : string) {
          console.log(hook)
        },
        toggleFullscreeen() {
          console.log("fullscreen")
          this.fullscreeen=!this.fullscreeen;
        }
      },
      watch : {
        theme() {
          console.log(this.theme)
        }
      },
      setup() {
        const theme = useTheme()

        return {
          theme
        }
      },
  })
</script>
<style>
/* unvisited link */
.brand-title {
  text-wrap: nowrap;
  font-size: 6rem;
  background-color: #0066FF;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 5px 15px;
}

a:link {
color: #0066FF;
}

/* visited link */
a:visited {
color: #80b3ff;
}
.blur-glass {
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.background2 {
  height: 270px;
  background-size: 100%;
  background-image: url('../assets/background2.svg');
  /* transform: translateY(-50px); */
}
.main-section {
  /* height: calc(100vh - 48px); */
  /* background-size: 100%; */
  /* background: linear-gradient(217deg, rgba(255, 100, 255, 0.492), rgba(255,0,0,0) 70.71%),
          linear-gradient(127deg, rgba(101, 228, 253, 0.8), rgba(0,255,0,0) 70.71%),
          linear-gradient(336deg, rgba(91, 91, 252, 0.8), rgba(0,0,255,0) 70.71%); */
          /* background-image: url('../assets/background1.svg'); */
}
/* .main-section-light {
  height: calc(100vh - 48px);
  background-image: url("../assets/nnnoise-whitebackground.svg");
  background-size: 100%;
  background: linear-gradient(217deg, rgba(255, 100, 255, 0.492), rgba(255,0,0,0) 70.71%),
          linear-gradient(127deg, rgba(101, 228, 253, 0.8), rgba(0,255,0,0) 70.71%),
          linear-gradient(336deg, rgba(91, 91, 252, 0.8), rgba(0,0,255,0) 70.71%);

}
*/
.vl {
  border-left: 2px solid rgba(117, 117, 117);
  height: 40px;
  margin: 2px 20px;
  border-radius: 25px;
}
.pt {
  height: 10px;
  width: 10px;
  background-color: rgba(117, 117, 117);
  border-radius: 20px;
  margin: 13px 16px;
  cursor: pointer;
}

.dark {
  background-color: rgba(34, 34, 34);
}
.my-row {
  margin: -25px 0px;
  margin-top: 12px;
}
.fade-out-top {  
  width: calc(100% - 30px);
  background-image: linear-gradient(rgba(255,255,255,1),90%,rgba(255,255,255,0));
  padding: 0px;
}
.fade-out-bottom {  
  background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,1));
  padding: 0px;
}
</style>
  
 <!-- <v-container>
    <v-row>
      
      <v-flex style="background-color: blue; position: fixed ">ici</v-flex>
      <v-col cols="6" style="background-color: red"></v-col>
      <v-col cols="6" style="background-color: green"></v-col>
    </v-row>
  </v-container> -->


    <!-- <info-bot/>
    <course-section/>
    <email-section/> -->


<!-- TIMELINE -->
<!-- <v-timeline side="end" align="start" density="compact" class="ma-3">
  <v-timeline-item
      size="x-small"
      height="20px"
    >
    <v-menu activator="#menu-activator">
      <div class="d-flex">
        <strong class="me-4">Time</strong>
        <div>
          <strong>Titel</strong>
          <div class="text-caption">
            subtitle
          </div>
        </div>
      </div>
    </v-menu>
  </v-timeline-item>
   <v-timeline-item
      size="x-small"
      height="20px"
    >
    <v-menu activator="#menu-activator">
      <div class="d-flex">
        <strong class="me-4">here </strong>
        <div>
          <strong>Titel</strong>
          <div class="text-caption">
            subtitle
          </div>
        </div>
      </div>
    </v-menu>
  </v-timeline-item>
</v-timeline> -->
  <!-- TIMNELINE -->
