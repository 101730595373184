<template>
  <v-container fluid fill-height class="blue-background">
    <v-container class="d-flex justify-center">
      <iframe
        src="https://towardsai-tutors-buster.hf.space?__theme=light"
        frameborder="0"
        width="100%"
        style="height: 100vh; max-height: 1100px;"
        class="blur-glass"
        ></iframe>
    </v-container>
  </v-container>
  
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelloWorld',

  data () {
    return {
      // 
    }
  },
})
</script>
<style>
.blur-glass {
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.blue-background {
  background-size: 100%;
  background-image: url('../assets/background1.svg');
}
</style>
