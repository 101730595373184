<template >
  <!-- <v-container class="pa-0"> -->
    
    <v-container class="mx-4 ">
      <v-row>
        <h1>Gen AI 360</h1>
      </v-row>
      <v-row >
        <v-col cols="12" sm="12" md="6" class="pa-0 pr-2">
          <p align="justify">
          The bot was developed for the (free) 
          <a href="https://learn.activeloop.ai/collections">GenAI 360 course suite</a>, a Foundational Model Certification, produced by Towards AI in collaboration with Activeloop and the Intel Disruptor Initiative with its most recent Free Course on 
          <a href="https://learn.activeloop.ai/courses/llms">Training and fine-tuning Large Language Models (LLMs) for Production!</a> 
          This course is for you if you want to learn how to train and fine-tune LLMs from scratch, and have intermediate Python knowledge as well as access to moderate compute resources (for some cases, just a Google Colab will suffice!).
        </p>
        <v-btn href="https://learn.activeloop.ai/courses/llms" class="my-10" variant="outlined" style="font-size: 1rem;">Start the course now <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="pa-0 pl-2">
          <iframe width="100%" height="300px" src="https://www.youtube.com/embed/iaYtuh5axJc" title="Train &amp; Fine-Tune Language Models for Production Course by Activeloop, Towards AI &amp; Intel Disruptor" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-col>
      </v-row>
    </v-container>
    
     <!-- </v-container> -->
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelloWorld',

  data () {
    return {
      // 
    }
  },
})
</script>
<style>
.second-section {
  background: linear-gradient(217deg, rgba(255, 100, 255, 0.492), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(101, 228, 253, 0.8), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(91, 91, 252, 0.8), rgba(0,0,255,0) 70.71%);
}
.blur-glass {
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

</style>
