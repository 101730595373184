<template >
  <v-container fluid fill-height>
     <v-container class="pa-0" >
    <h1 class="pb-2 mx-2">How was the AI tutor built?</h1>
    <p align="justify" class="mx-2">Our Generative AI retrieval augmented generation (RAG) Application was developed by Towards AI Inc building on top of the 
      <a href="https://github.com/jerpint/buster">open source QA documentation</a> chatbot Buster project.  We make use of OpenAI’s ada-002 embeddings model and GPT-Turbo 16k large language model for embedding and querying respectively. We experimented with multiple different OpenAI models to find the best balance of cost, context length and capability. We experimented with multiple changes to implementation, prompts, sourcing and chunking methods in combination with testing and user feedback to get to our final model. We use Activeloop to store and query our data as well as their Deep Memory feature to improve accuracy when querying our embeddings. We currently have over 21’000 chunks of data of around 500 words each from our ~100 lessons (including code projects) from the GenAI 360 “LangChain & Vector Databases in Production” and “Train & Fine-Tune LLMs for Production” courses, up-to-date Towards AI’s historic content library of ~4,000 AI tutorials and articles written by our contributor network, AI wikipedia pages and Hugging Face Transformers library documentation. Many of these contain information on new models and techniques which did not exist at the knowledge cut off dates of GPT-3 and GPT-4 training.
    </p>
    <br>
    <p class="mx-2">
        The bot automatically finds the most relevant answers to your question and cites the best sources it can find so you can dive in and look for more details, while having the benefit of reducing model hallucinations.
    </p>
    </v-container>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelloWorld',

  data () {
    return {
      // 
    }
  },
})
</script>
<style>
</style>
